/* eslint-disable @typescript-eslint/no-unused-vars */
import { get } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useExecScoutMutation } from '../redux/apiSlice'
import { setUseTicketAt } from '../redux/appSlice'
import { WorkerEntityType } from '../types'

type Props = {
  callback?: () => void
}

export const useTicket = ({ callback }: Props) => {
  const confirm = useConfirm()

  const { enqueueSnackbar } = useSnackbar()

  const [scoutMutation, mutationOptions] = useExecScoutMutation()

  const dispatch = useDispatch()

  const execScout = useCallback(
    async (workerId: WorkerEntityType['workerId']) => {
      try {
        // 確認
        await confirm({
          title: 'スカウトしますか？',
          description:
            'スカウトするとワーカーへスカウトメッセージを送ることができます',
          confirmationText: 'はい',
          cancellationText: 'いいえ',
        })
        // スカウトの実行
        scoutMutation(workerId)
        // カウント更新
        dispatch(setUseTicketAt(Date.now()))
      } catch (error) {}
    },
    [confirm, dispatch, scoutMutation]
  )

  useEffect(() => {
    if (mutationOptions.isError) {
      enqueueSnackbar(
        get(mutationOptions, 'error.data.message', 'エラーが発生しました'),
        {
          variant: 'error',
        }
      )
    }
    if (mutationOptions.isSuccess) {
      // ヘッダのチケット数更新
      dispatch(setUseTicketAt(Date.now()))
      enqueueSnackbar('スカウトしました')
      // コールバック実行
      if (callback) {
        callback()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, mutationOptions])

  return { execScout }
}
