import { FC } from 'react'
import { useParams } from 'react-router-dom'

import Routes from '~/client/Routes'
import { useGetRepostQuery } from '~/client/redux/apiSlice'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import ContentsLoading from '~/common/components/ContentsLoading'
import DetailRepost from '~/common/components/DetailRepost'
import ErrorBanner from '~/common/components/ErrorBanner'
import PageTitle from '~/common/components/PageTitle'

const PageRepostDetail: FC = () => {
  const { repostId } = useParams<{ repostId: string }>()
  const {
    data: repost,
    error,
    isSuccess,
    isLoading,
  } = useGetRepostQuery({ repostId })

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            path: Routes.reposts(),
            label: '再掲載リクエスト一覧',
          },
          {
            label: '再掲載リクエスト詳細',
          },
        ]}
      />
      <PageTitle title={`再掲載リクエスト詳細`} />
      <ErrorBanner error={error} />
      <ContentsLoading isLoading={isLoading} />
      {isSuccess && <DetailRepost repost={repost} />}
    </>
  )
}

export default PageRepostDetail
