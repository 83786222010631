/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Paper, Typography } from '@mui/material'
import { get, isUndefined } from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'

import type { LocationState } from '~/client/types'
import ErrorTextBanner from '~/common/components/ErrorTextBanner'
import TextField from '~/common/components/TextField'
import { Login } from '~/common/types/login'

import { useLoginMutation } from '../redux/apiSlice'

const validate = (values: Login) => {
  const errors: { [key in keyof Login]?: string } = {}

  if (isUndefined(values.loginName)) {
    errors.loginName = 'ログイン名を入力してください'
  }

  if (isUndefined(values.password)) {
    errors.password = 'パスワードを入力してください'
  }

  return errors
}

const PageLogin = () => {
  const [login, { isLoading, error, isSuccess }] = useLoginMutation()

  const { enqueueSnackbar } = useSnackbar()

  const errorText = useMemo(() => {
    if (get(error, 'status') === 401) {
      return 'ログイン情報に誤りがあります'
    }
    if (error) {
      return 'APIエラーが発生しました'
    }
  }, [error])

  const location = useLocation()
  const navigate = useNavigate()

  const from = (location.state as LocationState)?.from?.pathname || '/'

  useEffect(() => {
    if (!isSuccess) return
    navigate(from, { replace: true })
    enqueueSnackbar('ログインしました')
  }, [enqueueSnackbar, from, isSuccess, navigate])

  const handleSubmit = useCallback(
    (values: Login) => {
      login(values)
    },
    [login]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Paper>
        <Box sx={{ width: 500, p: 3 }}>
          <Form onSubmit={handleSubmit} validate={validate}>
            {({ handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit} noValidate autoComplete='nope'>
                  <Box mb={4}>
                    <Typography>ログイン</Typography>
                  </Box>
                  <ErrorTextBanner error={errorText} />
                  <Box mb={4}>
                    <TextField label='ログイン名' name='loginName' />
                  </Box>
                  <Box mb={4}>
                    <TextField
                      label='パスワード'
                      name='password'
                      type='password'
                    />
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Button
                      type='submit'
                      variant='contained'
                      disabled={isLoading}
                    >
                      ログイン
                    </Button>
                  </Box>
                </form>
              )
            }}
          </Form>
        </Box>
      </Paper>
    </Box>
  )
}

export default PageLogin
