/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  TextField,
} from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useState } from 'react'
import { useUnmount } from 'react-use'

import { useSendMessageMutation } from '~/client/redux/apiSlice'

import { messageTemplate } from './messageTemplate'

type Props = {
  open: boolean
  onCancel: () => void
  workerId: string
}

const MessageDialog = ({ workerId, open, onCancel }: Props) => {
  const [message, setMessage] = useState('')

  const [url, setUrl] = useState(``)

  const [sendMessage, sendMessageState] = useSendMessageMutation()

  const confirm = useConfirm()

  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = useCallback(async () => {
    try {
      await confirm({
        title: 'スカウトメッセージを送信しますか？',
        confirmationText: 'はい',
        cancellationText: 'いいえ',
      })
      sendMessage({ workerId, message, url })
    } catch (error) {}
  }, [confirm, message, sendMessage, url, workerId])

  useEffect(() => {
    if (sendMessageState.isError) {
      enqueueSnackbar('メッセージの送信に失敗しました', { variant: 'error' })
    }

    if (sendMessageState.isSuccess) {
      enqueueSnackbar('メッセージを送信しました')
      onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMessageState])

  useUnmount(() => {
    setMessage('')
  })

  return (
    <Dialog open={open} scroll='paper' fullWidth maxWidth='md'>
      <DialogTitle>スカウトメッセージ</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Box mb={3}>
            <Button
              variant='contained'
              onClick={() => {
                setMessage(messageTemplate)
              }}
            >
              テンプレートから入力
            </Button>
          </Box>
          <Box mb={3}>
            <TextField
              fullWidth
              label='メッセージ本文'
              minRows={5}
              multiline
              autoFocus
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
            />
          </Box>
          <Box>
            <TextField
              fullWidth
              label='求人募集URL'
              value={url}
              placeholder='募集したい求人のURLをいれてください(外部サイトを含む)'
              onChange={(e) => {
                setUrl(e.target.value)
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancel()}>キャンセル</Button>
        <Button
          disabled={!message || sendMessageState.isLoading}
          onClick={handleSubmit}
        >
          送信
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MessageDialog
