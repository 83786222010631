import PageNotFound from '~/common/components/PageNotFound'

const Page404 = () => {
  return (
    <>
      <PageNotFound />
    </>
  )
}

export default Page404
