import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import qs from 'qs'

import { TOKEN_KEY } from '~/client/constants'
import { Applicant } from '~/common/types/applicant'
import { Client } from '~/common/types/client'
import type { Paging } from '~/common/types/common'
import { ApiSearchValues } from '~/common/types/common'
import type { Login, LoginResponse } from '~/common/types/login'
import { Repost } from '~/common/types/repost'

import { SearchProps } from '../components/WorkerSearch'
import { TicketInfos, WorkerEntityType } from '../types'

export const clientApi = createApi({
  reducerPath: 'clientApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(TOKEN_KEY)

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }

      return headers
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, Login>({
      query: (body) => ({
        url: `login`,
        method: 'POST',
        body,
      }),
    }),
    getApplicants: builder.query<Paging<Applicant>, ApiSearchValues>({
      query: (values) => `applicant?${qs.stringify(values)}`,
    }),
    getApplicant: builder.query<Applicant, { applicantId: string | undefined }>(
      {
        query: ({ applicantId }) => `applicant/${applicantId}`,
      }
    ),
    updateNote: builder.mutation<Applicant, Applicant>({
      query: ({ applicantId, note }) => ({
        url: `applicant/${applicantId}`,
        method: 'PUT',
        body: { note },
      }),
    }),
    getReposts: builder.query<Paging<Repost>, number | void>({
      query: (page) => `repost?page=${page}`,
    }),
    getRepost: builder.query<Repost, { repostId: string | undefined }>({
      query: ({ repostId }) => `repost/${repostId}`,
    }),
    getMe: builder.query<Client, void>({
      query: () => `client/me`,
    }),
    putMe: builder.mutation<LoginResponse, { subMailAddresses: string[] }>({
      query: (body) => ({
        url: `/me`,
        method: 'PUT',
        body,
      }),
    }),
    getFormName: builder.query<{ names: string[] }, void>({
      query: () => `applicant/form-name`,
    }),
    getWorkers: builder.query<
      Paging<WorkerEntityType>,
      Partial<SearchProps> & { page: number | undefined }
    >({
      query: (values) => `client/worker?${qs.stringify(values)}`,
    }),
    getWorker: builder.query<WorkerEntityType, string>({
      query: (workerId: string) => `client/worker/${workerId}`,
    }),
    getWorkerPurchased: builder.query<WorkerEntityType, string>({
      query: (workerId: string) => `client/worker/${workerId}/purchased`,
    }),
    execScout: builder.mutation<Applicant, WorkerEntityType['workerId']>({
      query: (workerId) => ({
        url: `client/worker/${workerId}/ticket`,
        method: 'POST',
      }),
    }),
    getTicketInfos: builder.query<TicketInfos, Client['clientId'] | undefined>({
      query: (clientId) => {
        return `client/${clientId}/ticket/v2`
      },
    }),
    addFavorite: builder.mutation<void, WorkerEntityType['workerId']>({
      query: (workerId) => ({
        url: `client/worker/${workerId}/favorite`,
        method: 'POST',
      }),
    }),
    deleteFavorite: builder.mutation<void, WorkerEntityType['workerId']>({
      query: (workerId) => ({
        url: `client/worker/${workerId}/favorite`,
        method: 'DELETE',
      }),
    }),
    sendMessage: builder.mutation<
      void,
      { workerId: string; message: string; url: string }
    >({
      query: ({ workerId, message, url }) => ({
        url: `client/worker/${workerId}/scout`,
        method: 'POST',
        body: { message, url },
      }),
    }),
    getMessageHistory: builder.query<
      { message: string; url: string; createdAt: string },
      WorkerEntityType['workerId']
    >({
      query: (workerId) => `client/worker/${workerId}/scout`,
    }),
  }),
})

export const {
  useLoginMutation,
  useGetApplicantQuery,
  useGetApplicantsQuery,
  useGetRepostQuery,
  useGetRepostsQuery,
  useGetMeQuery,
  useGetFormNameQuery,
  usePutMeMutation,
  useUpdateNoteMutation,
  useGetWorkersQuery,
  useExecScoutMutation,
  useGetTicketInfosQuery,
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
  useGetWorkerQuery,
  useGetWorkerPurchasedQuery,
  useSendMessageMutation,
  useGetMessageHistoryQuery,
} = clientApi
