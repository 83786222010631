import { Box, Typography } from '@mui/material'

const PageNotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <Typography
        sx={{
          fontSize: '30px',
        }}
      >
        Page Not Found
      </Typography>
    </Box>
  )
}

export default PageNotFound
