/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper } from '@mui/material'
import { isUndefined } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { WorkerDetailHead } from '~/client/components/WorkerDetailHead'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import PageTitle from '~/common/components/PageTitle'

import { useGetWorkerQuery } from '../../redux/apiSlice'

const PageScoutDetail = () => {
  const { workerId } = useParams<{ workerId: string }>()

  const { pathname } = useLocation()

  const {
    data: worker,
    isLoading: isLoadingWorker,
    refetch,
  } = useGetWorkerQuery(workerId as string, { skip: isUndefined(workerId) })

  const iframeUrl = useMemo(() => {
    if (!workerId) return ``
    if (!worker || !worker.purchasedTicket)
      return `https://worker.mei-kyu.com/workers/${workerId}?type=scout`

    return `https://worker.mei-kyu.com/workers/${workerId}?type=limited`
  }, [worker, workerId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: 'サクサクスカウト 登録者一覧',
            path: '/scouts',
          },
          {
            label: 'ワーカー詳細',
          },
        ]}
      />
      <PageTitle title={`ワーカー詳細`}></PageTitle>
      <WorkerDetailHead
        workerId={workerId as string}
        worker={worker}
        isLoadingWorker={isLoadingWorker}
        refetch={refetch}
      />
      <Paper>
        <iframe
          src={iframeUrl}
          title='ワーカー'
          style={{
            width: '100%',
            height: '3000px',
            border: '1px solid #CCC',
          }}
        ></iframe>
      </Paper>
    </>
  )
}

export default PageScoutDetail
