import { TOKEN_KEY } from '~/client/constants'

const downloadCsv = (file: string, fileName: string) => {
  const anchor = document.createElement('a')
  document.body.appendChild(anchor)
  const headers = new Headers()
  headers.append('Authorization', `Bearer ${localStorage.getItem(TOKEN_KEY)}`)
  fetch(file, { headers })
    .then((response) => response.blob())
    .then((blobData) => {
      const objectUrl = window.URL.createObjectURL(blobData)
      anchor.href = objectUrl
      anchor.download = fileName
      anchor.click()
      window.URL.revokeObjectURL(objectUrl)
      anchor.remove()
    })
}

export default downloadCsv
