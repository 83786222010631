/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@mui/material'
import { isNull } from 'lodash'
import { FC, useMemo } from 'react'

export type SearchValues = {
  sort: string
  name: string
  requestForRegistration: string
}

type Props = {
  onSearch: (key: string, value: string) => void
  values: SearchValues
  formNames: string[]
}

const ApplicantSearchMenu: FC<Props> = ({
  onSearch,
  values: { sort, name, requestForRegistration },
  formNames,
}) => {
  const cleanedFormNames = useMemo(() => {
    return formNames.filter((name) => !isNull(name))
  }, [formNames])

  return (
    <Box
      sx={{
        marginBottom: 2,
        display: 'flex',
        gap: 2,
        justifyContent: 'end',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: 300 }}>
        <FormControl size='small' fullWidth>
          <InputLabel>並び順</InputLabel>
          <Select
            label='並び順'
            value={sort}
            onChange={(e) => {
              onSearch('sort', e.target.value as string)
            }}
          >
            <MenuItem value='newer'>応募日が新しい</MenuItem>
            <MenuItem value='older'>応募日が古い</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={{ width: 300 }}>
        <FormControl size='small' fullWidth>
          <InputLabel>募集職種検索</InputLabel>
          <Select
            label='募集職種検索'
            value={name}
            onChange={(e) => {
              onSearch('name', e.target.value as string)
            }}
          >
            <MenuItem value='all'>すべての職種</MenuItem>
            {cleanedFormNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size='small'
                checked={requestForRegistration === '1'}
                onChange={(e) => {
                  onSearch(
                    'requestForRegistration',
                    e.target.checked ? '1' : '0'
                  )
                }}
              />
            }
            label='スカウト代行登録'
          />
        </FormGroup>
      </Box>
    </Box>
  )
}

export default ApplicantSearchMenu
