/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper, Box } from '@mui/material'
import { isUndefined } from 'lodash'
import { FC } from 'react'

import ConfirmField from '~/common/components/ConfirmField'
import useFormName from '~/common/hooks/useFormName'
import { formatJstTime } from '~/common/libs/timeUtils'
import type { Applicant } from '~/common/types/applicant'

import ConfirmFileField from './ConfirmFileField'

type Props = {
  applicant?: Applicant
  isAdmin?: boolean
}

const DetailApplicant: FC<Props> = ({ applicant, isAdmin = true }) => {
  const formName = useFormName()

  if (isUndefined(applicant)) return null

  return (
    <Paper sx={{ py: 2, px: 5 }}>
      <Box sx={{ display: 'grid', rowGap: '15px' }}>
        <ConfirmField label='募集職種' value={applicant.formName} />
        <ConfirmField
          label='フォームタイプ'
          value={formName(applicant.formType)}
        />
        <ConfirmField label='ID' value={applicant.applicantId} />
        <ConfirmField
          label='応募日'
          value={formatJstTime(applicant.createdAt)}
        />
        <ConfirmField
          label='名前'
          value={`${applicant.lastName} ${applicant.firstName}`}
        />
        <ConfirmField label='電話番号' value={applicant.tel} />
        <ConfirmField
          label='メールアドレス'
          value={applicant.mailAddress}
          link={`mailto:${applicant.mailAddress}`}
        />
        <ConfirmField label='住所(郵便番号)' value={applicant.postal} />
        <ConfirmField label='住所(都道府県)' value={applicant.addressCity} />
        <ConfirmField
          label='住所(市区町村番地)'
          value={applicant.addressHomeNumber}
        />
        <ConfirmField
          label='住所(マンション・ビル名)'
          value={applicant.addressBuildingName}
        />
        <ConfirmField label='自己PR' value={applicant.selfPr} />
        <ConfirmField
          label='応募理由(話を聞きたい理由)'
          value={applicant.reason}
        />
        <ConfirmField label='質問事項' value={applicant.question} />
        <ConfirmFileField
          label='履歴書'
          value={applicant.resumeFile}
          isAdmin={isAdmin}
          downloadFileType='RESUME'
        />
        <ConfirmField
          label='履歴書URL'
          value={applicant.resumeUrl}
          link={applicant.resumeUrl}
          target='_blank'
        />
        <ConfirmFileField
          label='スキルシート'
          value={applicant.skillSheetFile}
          isAdmin={isAdmin}
          downloadFileType='SKILL_SHEET'
        />
        <ConfirmField
          label='スキルシートURL'
          value={applicant.skillSheetUrl}
          link={applicant.skillSheetUrl}
          target='_blank'
        />
        <ConfirmFileField
          label='ポートフォリオ'
          value={applicant.portfolioFile}
          isAdmin={isAdmin}
          downloadFileType='PORTFOLIO'
        />
        <ConfirmField
          label='ポートフォリオURL'
          value={applicant.portfolioUrl}
          link={applicant.portfolioUrl}
          target='_blank'
        />
        <ConfirmField
          label='使用可能言語'
          value={applicant.availableLanguages}
        />
        <ConfirmField
          label='使用可能ソフトウェア'
          value={applicant.availableSoftwares}
        />
        <ConfirmField
          label='あなたのことがわかるURL'
          value={applicant.socialNetworkUrls}
          link={applicant.socialNetworkUrls}
          target='_blank'
        />
      </Box>
    </Paper>
  )
}

export default DetailApplicant
