import { Typography, Box } from '@mui/material'
import { FC } from 'react'

type Props = {
  title?: string
}

const PageTitle: FC<Props> = ({ title, children }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
      <Typography
        variant='h2'
        component='div'
        sx={{ fontWeight: 'bold', fontSize: '1.5rem', flexGrow: 1 }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default PageTitle
