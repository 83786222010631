import { Box, Button, Paper, Typography } from '@mui/material'

export const ScoutApplicationBanner = () => {
  return (
    <Box mb={3}>
      <Paper>
        <Box
          p={3}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Button
            variant='contained'
            size='large'
            sx={{ fontWeight: 'bold' }}
            href='https://okanechips.mei-kyu.com/tickets/'
            target='_blank'
          >
            スカウト申し込みはこちら
          </Button>
          <Typography fontSize={14}>
            スカウト機能に申し込むとワーカーにメッセージが送れたり履歴書を閲覧することが可能になります。
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}
