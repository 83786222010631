/* eslint-disable @typescript-eslint/no-unused-vars */
import { Typography, styled } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { parseCompanyList } from '~/client/libs/parseCompanyList'
import { parseStringToArray } from '~/client/libs/parseStirngToArray'
import { WorkerEntityType } from '~/client/types'
import { formatJstTime } from '~/common/libs/timeUtils'
import { truncate } from '~/common/libs/utils'

import { NormalList } from './NormalList'

type Props = {
  worker: WorkerEntityType
  isDetail?: boolean
  isScouted?: boolean
}

const Table = styled('table')({
  width: '100%',
  tableLayout: 'fixed',
  borderCollapse: 'collapse',
})

const Th = styled('th')({
  fontSize: '12px',
  background: '#64b5f6',
  fontWeight: 'normal',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  color: '#fff',
})

const Td = styled('td')({
  fontSize: '12px',
  border: '1px solid rgba(0, 0, 0, 0.12)',
  padding: '5px',
  background: '#fff',
})

const WorkerInfoTable = ({
  worker,
  isDetail = false,
  isScouted = false,
}: Props) => {
  const workStyle = parseStringToArray(worker.workStyle)
  const interestOccupation = parseStringToArray(worker.interestOccupation)

  const companyList = parseCompanyList(worker.jobHistory)

  return (
    <Table>
      {isScouted && (
        <thead>
          <tr>
            <Th>氏名</Th>
            <Th>年齢</Th>
            <Th>都道府県</Th>
            <Th>最終ログイン</Th>
            <Th>登録日</Th>
          </tr>
        </thead>
      )}
      {isScouted && (
        <tbody>
          <tr>
            <Td>
              {worker.lastName} {worker.firstName}
            </Td>
            <Td align='center'>{worker.age}歳</Td>
            <Td align='center'>{worker.prefecture}</Td>
            <Td align='center'>
              {formatDistanceToNow(new Date(worker.lastLoginAt), {
                locale: ja,
              })}
            </Td>
            <Td align='center'>{formatJstTime(worker.createdAt)}</Td>
          </tr>
        </tbody>
      )}
      <thead>
        <tr>
          <Th>希望の就労形態</Th>
          <Th>働き方の状況</Th>
          <Th>興味のある職種</Th>
          <Th>所属会社</Th>
          <Th>現在の年収または希望年収</Th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <Td>
            <NormalList list={workStyle} />
          </Td>
          <Td>
            <Typography fontSize={12}>{worker.jobChangeRequest}</Typography>
          </Td>
          <Td>
            <NormalList list={interestOccupation} />
          </Td>
          <Td>
            <NormalList list={companyList} />
          </Td>
          <Td>
            <Typography fontSize={10}>{worker.estimatedOrderAmount}</Typography>
          </Td>
        </tr>
        <tr>
          <Th colSpan={5}>自己PR</Th>
        </tr>
        <tr>
          <Td colSpan={5}>
            <Typography
              fontSize={12}
              sx={{
                whiteSpace: 'pre-wrap',
              }}
            >
              {isDetail ? worker.selfPr : truncate(worker.selfPr ?? '', 140)}
            </Typography>
          </Td>
        </tr>
      </tbody>
    </Table>
  )
}

export default WorkerInfoTable
