/* eslint-disable @typescript-eslint/no-unused-vars */
import { Alert, AlertTitle } from '@mui/material'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { isUndefined } from 'lodash'
import { FC } from 'react'

type Props = {
  error: FetchBaseQueryError | SerializedError | undefined
}

const renderErrorChild = (error: FetchBaseQueryError | SerializedError) => {
  if ('status' in error) {
    return (
      <>
        <AlertTitle>Error</AlertTitle>
        {'error' in error ? error.error : JSON.stringify(error.data)}
      </>
    )
  }

  return <>{error.message}</>
}

const ErrorBanner: FC<Props> = ({ error }) => {
  if (isUndefined(error)) return null

  return (
    <Alert severity='error' sx={{ mb: 5 }}>
      {renderErrorChild(error)}
    </Alert>
  )
}

export default ErrorBanner
