import { useCallback } from 'react'

import downloadCsv from '~/client/libs/downloadCsv'
import {
  useGetApplicantsQuery,
  useGetFormNameQuery,
} from '~/client/redux/apiSlice'
import ApplicantCsvDownloader from '~/common/components/ApplicantCsvDownloader'
import ApplicantSearchMenu from '~/common/components/ApplicantSearchMenu'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import ContentsLoading from '~/common/components/ContentsLoading'
import ErrorBanner from '~/common/components/ErrorBanner'
import PageTitle from '~/common/components/PageTitle'
import Pagination from '~/common/components/Pagination'
import TableApplicants from '~/common/components/TableApplicants'
import useApplicantSearch from '~/common/hooks/useApplicantSearch'

const PageApplicants = () => {
  const { clientSearchValues, apiSearchValues, onSearchApplicant } =
    useApplicantSearch()

  const { data, error, isSuccess, isLoading } =
    useGetApplicantsQuery(apiSearchValues)

  const { data: formNames } = useGetFormNameQuery()

  const handleDownloadCsv = useCallback((formType) => {
    let url = `${process.env.API_URL}applicant/csv`
    if (formType !== 'all') {
      url += `?name=${formType}`
    }
    downloadCsv(url, `applicant-${formType}.csv`)
  }, [])

  const applicants = data?.data || []

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: '採用応募者一覧',
          },
        ]}
      />
      <PageTitle title={`採用応募者一覧`}>
        <ApplicantCsvDownloader
          onDownload={handleDownloadCsv}
          formNames={formNames?.names || []}
        />
      </PageTitle>
      <ErrorBanner error={error} />
      <ContentsLoading isLoading={isLoading} />
      {isSuccess && (
        <>
          <ApplicantSearchMenu
            onSearch={onSearchApplicant}
            values={clientSearchValues}
            formNames={formNames?.names || []}
          />
          <Pagination all={data?.all} />
          <TableApplicants applicants={applicants} place='client' />
          <Pagination all={data?.all} />
        </>
      )}
    </>
  )
}

export default PageApplicants
