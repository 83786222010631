/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from 'react-redux'

import { WorkerEntityType } from '~/client/types'

import { ScoutApplicationBanner } from '../ScoutApplicationBanner'

import { ExecScout } from './ExecScout'
import { WorkerInfos } from './WorkerInfos'

type Props = {
  workerId: string
  worker?: WorkerEntityType
  isLoadingWorker: boolean
  refetch: () => void
}

export const WorkerDetailHead = ({
  workerId,
  worker,
  isLoadingWorker,
  refetch,
}: Props) => {
  // スカウト可能か否か
  const enableScout = useSelector((state) => state.app.enableScout)

  // すでにスカウト済か否か
  const purchasedTicket = worker ? worker.purchasedTicket : false

  if (isLoadingWorker) return null

  if (!enableScout) return <ScoutApplicationBanner />

  if (!purchasedTicket)
    return <ExecScout workerId={workerId} refetch={refetch} />

  return <WorkerInfos workerId={workerId} purchasedTicket={purchasedTicket} />
}
