import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Box } from '@mui/system'

import { formatJstTime } from '~/common/libs/timeUtils'

/* eslint-disable @typescript-eslint/no-unused-vars */
type Props = {
  message?: {
    message: string
    url: string
    createdAt: string
  }
  onClose: () => void
  open: boolean
}

export const MessageHistoryDialog = ({ message, open, onClose }: Props) => {
  if (!message) return null

  return (
    <Dialog open={open} scroll='paper' fullWidth maxWidth='md'>
      <DialogTitle
        sx={{
          display: 'flex',
        }}
      >
        <Box sx={{ flex: 1, justifyContent: 'center' }}>
          前回送信したメッセージ
        </Box>
        <Box
          sx={{
            fontSize: '12px',
          }}
        >
          送信日: {formatJstTime(message.createdAt)}
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {message.message}
        </DialogContentText>
        {message.url && (
          <Box
            mt={1}
            sx={{
              fontSize: '12px',
            }}
          >
            求人募集URL:{' '}
            <a href={message.url} target='_blank' rel='noreferrer'>
              {message.url}
            </a>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  )
}
