import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetApplicantQuery,
  useUpdateNoteMutation,
} from '~/client/redux/apiSlice'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import ContentsLoading from '~/common/components/ContentsLoading'
import DetailApplicant from '~/common/components/DetailApplicant'
import ErrorBanner from '~/common/components/ErrorBanner'
import Note from '~/common/components/Note'
import PageTitle from '~/common/components/PageTitle'
import { Applicant } from '~/common/types/applicant'

const PageApplicantDetail = () => {
  const { applicantId } = useParams<{ applicantId: string }>()

  const [updateNote] = useUpdateNoteMutation()

  const { enqueueSnackbar } = useSnackbar()

  const {
    data: applicant,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useGetApplicantQuery({ applicantId })

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await updateNote(values)
        refetch()
        enqueueSnackbar(`メモを登録しました`)
      } catch (error) {
        console.log(error)
      }
    },
    [enqueueSnackbar, refetch, updateNote]
  )

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            path: '/',
            label: '採用応募者一覧',
          },
          {
            label: '採用応募者詳細',
          },
        ]}
      />
      <PageTitle title={`採用応募者詳細`} />
      <ErrorBanner error={error} />
      <ContentsLoading isLoading={isLoading} />
      {isSuccess && (
        <Box mb={2}>
          <Note<Applicant> values={applicant} onSubmit={handleSubmit} />
        </Box>
      )}
      {isSuccess && <DetailApplicant applicant={applicant} isAdmin={false} />}
    </>
  )
}

export default PageApplicantDetail
