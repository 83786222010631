class Routes {
  static home() {
    return `/`
  }

  static login() {
    return `/login`
  }

  static applicants() {
    return `/applicants`
  }

  static applicant(applicantId: string) {
    return `/applicants/${applicantId}`
  }

  static reposts() {
    return `/reposts`
  }

  static repost(repostId: string) {
    return `/reposts/${repostId}`
  }

  static notification() {
    return `/notification`
  }

  static scouts() {
    return `/scouts`
  }
}

export default Routes
