import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { SearchValues } from '~/common/components/ApplicantSearchMenu'
import usePageParams from '~/common/hooks/usePageParams'
import { ApiSearchValues } from '~/common/types/common'

const useApplicantSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const page = usePageParams()

  const clientSearchValues: SearchValues = useMemo(() => {
    const sort = searchParams.get('sort') || 'newer'
    const name = searchParams.get('name') || 'all'
    const requestForRegistration =
      searchParams.get('requestForRegistration') || '0'

    return {
      sort,
      name,
      requestForRegistration,
    }
  }, [searchParams])

  const apiSearchValues = useMemo(() => {
    const values: ApiSearchValues = { page }
    const sort = searchParams.get('sort')
    const name = searchParams.get('name')
    const requestForRegistration = searchParams.get('requestForRegistration')

    if (sort) {
      values.sort = sort
    }

    if (name && name !== 'all') {
      values.name = name
    }

    if (requestForRegistration) {
      values.requestForRegistration = requestForRegistration
    }

    return values
  }, [page, searchParams])

  const onSearchApplicant = useCallback(
    (key: string, value: string) => {
      searchParams.set('page', '1')
      searchParams.set(key, value)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  return {
    clientSearchValues,
    apiSearchValues,
    onSearchApplicant,
  }
}

export default useApplicantSearch
