/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom'

import workKunImg from '../assets/img/work-kun.png'

const PageHome = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
        }}
      >
        <Container>
          <Box
            sx={{
              mb: 4,
              textAlign: 'center',
            }}
          >
            <img src={workKunImg} alt='' />
            <Typography
              variant='h1'
              sx={{
                fontSize: '25px',
                fontWeight: 'bold',
                mt: 2,
              }}
            >
              サクサク仕事探し 管理画面
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card variant='outlined'>
                <CardContent sx={{ minHeight: '200px' }}>
                  <Typography fontWeight='bold' mb={2}>
                    採用応募者一覧
                  </Typography>
                  サクサク仕事探しの求人募集ページから応募があった応募者の一覧ページです。
                </CardContent>
                <CardActions>
                  <Button to='/applicants' component={Link}>
                    採用応募者一覧
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant='outlined'>
                <CardContent sx={{ minHeight: '200px' }}>
                  <Typography fontWeight='bold' mb={2}>
                    再掲載リクエスト一覧
                  </Typography>
                  サクサク仕事探しの再掲載リクエストから応募があった一覧ページです。
                </CardContent>
                <CardActions>
                  <Button to='/reposts' component={Link}>
                    再掲載リクエスト一覧
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card variant='outlined'>
                <CardContent sx={{ minHeight: '200px' }}>
                  <Typography fontWeight='bold' mb={2}>
                    サクサクスカウト登録者一覧
                  </Typography>
                  サクサクスカウトに登録したワーカーの一覧ページです。有料会員様はスカウトメッセージを送ることができます。
                </CardContent>
                <CardActions>
                  <Button to='/scouts' component={Link}>
                    サクサクスカウト登録者一覧
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          py: 2,
          px: 3,
          textAlign: 'right',
        }}
      >
        <Typography
          sx={{
            color: '#666',
            fontSize: '12px',
          }}
        >
          © TIME MACHINE Inc. All Rights Reserved
        </Typography>
      </Box>
    </Box>
  )
}

export default PageHome
