export const FORM_TYPES = [
  {
    id: 'LIGHT',
    name: '話をきく(職種ページ)',
    applicant: true,
  },
  {
    id: 'LIGHT_BAKUSOKU',
    name: '話をきく(爆速)',
    applicant: true,
  },
  {
    id: 'BAKUSOKU',
    name: '爆速',
    applicant: true,
  },
  {
    id: 'LEVEL4',
    name: '応募難易度Lv4',
    applicant: true,
  },
  {
    id: 'LEVEL3',
    name: '応募難易度Lv3',
    applicant: true,
  },
  {
    id: 'LEVEL2',
    name: '応募難易度Lv2',
    applicant: true,
  },
  {
    id: 'LEVEL1',
    name: '応募難易度Lv1',
    applicant: true,
  },
  {
    id: 'CREATIVE_W_PORTFOLIO',
    name: 'クリエイティブ系（ポートフォリオあり）',
    applicant: true,
  },
  {
    id: 'CREATIVE',
    name: 'クリエイティブ系（ポートフォリオなし）',
    applicant: true,
  },
  {
    id: 'CREATIVE_PART_TIMER',
    name: 'クリエイティブ系アルバイト',
    applicant: true,
  },
  {
    id: 'GENERAL_PART_TIMER',
    name: 'アルバイト',
    applicant: true,
  },
  {
    id: 'SALES',
    name: '営業・フィールドセールス',
    applicant: true,
  },
  {
    id: 'BACK_OFFICE',
    name: 'バックオフィス(事務)',
    applicant: true,
  },
  {
    id: 'PROGRAMMER',
    name: 'プログラマー',
    applicant: true,
  },
  {
    id: 'PHOTOGRAPHER',
    name: 'カメラマン',
    applicant: true,
  },
  {
    id: 'WRITER',
    name: '編集・ライター',
    applicant: true,
  },
  {
    id: 'VIDEO_EDITOR',
    name: '映像・動画エディター',
    applicant: true,
  },
  {
    id: 'VIDEO_DIRECTOR',
    name: '映像・動画ディレクター',
    applicant: true,
  },
  {
    id: 'MOTION_GRAPHER',
    name: 'モーショングラファー',
    applicant: true,
  },
  {
    id: 'CG_DESIGNER',
    name: '3DCGデザイナー',
    applicant: true,
  },
  {
    id: 'DESIGNER',
    name: 'デザイナー',
    applicant: true,
  },
  {
    id: 'PRODUCER',
    name: 'プロデューサー',
    applicant: true,
  },
  {
    id: 'PRODUCTION_MANAGER',
    name: 'プロダクションマネージャー',
    applicant: true,
  },
  {
    id: 'PROJECT_MANAGER',
    name: 'プロジェクトマネージャー',
    applicant: true,
  },
  {
    id: 'SELF_RECOMMENDED',
    name: '自薦',
    applicant: true,
  },
  {
    id: 'MARKETTER',
    name: 'マーケター',
    applicant: true,
  },
  {
    id: 'CUSTOMER_SUCCESS',
    name: 'カスタマーサクセス',
    applicant: true,
  },
  {
    id: 'UI_UX_DESIGNER',
    name: 'UI/UXデザイナー',
    applicant: true,
  },
  {
    id: 'ILLUSTRATOR',
    name: 'イラストレーター',
    applicant: true,
  },
  {
    id: 'WEB_DIRECTOR',
    name: 'ウェブディレクター',
    applicant: true,
  },
  {
    id: 'WEB_DESIGNER',
    name: 'ウェブデザイナー',
    applicant: true,
  },
  {
    id: 'PROMOTION',
    name: '広報・宣伝',
    applicant: true,
  },
  {
    id: 'PLANNING',
    name: '商品企画・サービス企画',
    applicant: true,
  },
  {
    id: 'HUMAN_RESOURCES',
    name: '人事・採用・労務',
    applicant: true,
  },
  {
    id: 'INSIDE_SALES',
    name: 'インサイドセールス',
    applicant: true,
  },
  {
    id: 'RESEARCHER',
    name: 'リサーチャー・アナリスト',
    applicant: true,
  },
  {
    id: 'CONSULTANT',
    name: 'コンサルタント',
    applicant: true,
  },
  {
    id: 'FINANCE',
    name: '財務・会計・経理',
    applicant: true,
  },
  {
    id: 'CAPITALIST',
    name: 'キャピタリスト',
    applicant: true,
  },
  {
    id: 'REGISTER',
    name: 'クライアント登録',
    applicant: false,
  },
  {
    id: 'REPOST',
    name: '再掲載リクエスト',
    applicant: false,
  },
] as const

export default FORM_TYPES

export const FORM_TYPE_IDS = FORM_TYPES.map((o) => o.id)

export const APPLICANT_FORM_TYPES = FORM_TYPES.filter((o) => !!o.applicant)

export const APPLICANT_FORM_TYPE_IDS = APPLICANT_FORM_TYPES.map((o) => o.id)

export type FormTypeId = typeof FORM_TYPES[number]['id']

export type ApplicantFormTypeId = typeof APPLICANT_FORM_TYPES[number]['id']
