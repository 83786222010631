/* eslint-disable @typescript-eslint/no-unused-vars */
import EventNoteIcon from '@mui/icons-material/EventNote'
import { Paper, Typography, Box, Button, TextField } from '@mui/material'
import { get } from 'lodash'
import { useCallback, useState } from 'react'
import { useMount } from 'react-use'

type Props<T> = {
  values?: T
  onSubmit: (values: T) => void
}

const getMemo = (jsonStr: string | null | undefined) => {
  if (!jsonStr) return ''
  const json = JSON.parse(jsonStr)

  return get(json, 'memo', '') as string
}

const Note = <T extends { note: string | null }>(props: Props<T>) => {
  const { values, onSubmit } = props

  const [memo, setMemo] = useState(getMemo(values?.note))

  const [isEdit, setIsEdit] = useState(false)

  const handleSubmit = useCallback(() => {
    const noteJson = values?.note ? JSON.parse(values.note) : {}
    const mergedJson = { ...noteJson, memo }
    onSubmit({ ...values, note: JSON.stringify(mergedJson) } as T)
    setIsEdit(false)
  }, [memo, onSubmit, values])

  useMount(() => {
    if (!memo) {
      setIsEdit(true)
    }
  })

  if (!values) return null

  return (
    <Paper sx={{ py: 2, px: 5 }}>
      <Box sx={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', gap: '5px' }}>
          <EventNoteIcon />
          <Typography
            sx={{
              fontWeight: 'bold',
            }}
          >
            メモ
          </Typography>
        </Box>
        {isEdit ? (
          <Button
            size='small'
            variant='outlined'
            type='submit'
            onClick={() => {
              handleSubmit()
            }}
            disabled={!memo}
          >
            登録する
          </Button>
        ) : (
          <Button
            size='small'
            variant='outlined'
            type='button'
            onClick={() => {
              setIsEdit(true)
            }}
          >
            編集する
          </Button>
        )}
      </Box>
      {isEdit ? (
        <Box mt={2}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value as string)
            }}
          />
        </Box>
      ) : (
        <Box mt={memo ? 2 : 0}>
          <Typography sx={{ fontSize: '13px', whiteSpace: 'pre-wrap' }}>
            {memo}
          </Typography>
        </Box>
      )}
    </Paper>
  )
}

export default Note
