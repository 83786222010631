import {
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
  TableCellProps,
} from '@mui/material'

export type HeadCell<T> = {
  id: keyof T
  label: string
  align?: TableCellProps['align']
}

const TableHead = <T extends unknown>(props: { headCells: HeadCell<T>[] }) => {
  const { headCells } = props

  return (
    <MuiTableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.align || 'left'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  )
}

export default TableHead
