import { useCallback } from 'react'

import FORM_TYPES, { FormTypeId } from '../constants/formTypes'

const useFormName = () => {
  const formName = useCallback((formTypeId: FormTypeId) => {
    return FORM_TYPES.find((o) => o.id === formTypeId)?.name
  }, [])

  return formName
}

export default useFormName
