import CircleIcon from '@mui/icons-material/Circle'
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Link as MuiLink,
} from '@mui/material'
import { get } from 'lodash'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import AdminRoutes from '~/admin/Routes'
import ClientRoutes from '~/client/Routes'
import TableHead, { HeadCell } from '~/common/components/TableHead'
import { formatJstTime } from '~/common/libs/timeUtils'
import { Applicant } from '~/common/types/applicant'

type Props = {
  applicants: Applicant[]
  place?: 'admin' | 'client'
}

type HeadCellProps = Pick<
  Applicant,
  | 'applicantId'
  | 'formType'
  | 'createdAt'
  | 'mailAddress'
  | 'formName'
  | 'acceptMailMagazine'
  | 'note'
  | 'employmentStatus'
  | 'requestForRegistration'
> & {
  name: string
}

const getMemo = (jsonStr: string | null | undefined) => {
  if (!jsonStr) return ''
  const json = JSON.parse(jsonStr)

  return get(json, 'memo', '') as string
}

const headCells: HeadCell<HeadCellProps>[] = [
  {
    id: 'createdAt',
    label: '応募日',
    align: 'left',
  },
  {
    id: 'applicantId',
    label: 'ID',
    align: 'center',
  },
  {
    id: 'formName',
    label: '募集職種',
    align: 'left',
  },
  {
    id: 'name',
    label: '応募者名',
    align: 'left',
  },
  {
    id: 'mailAddress',
    label: 'メールアドレス',
    align: 'left',
  },
]

const TableApplicants: FC<Props> = ({ applicants, place = 'admin' }) => {
  return (
    <>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead<HeadCellProps>
              headCells={
                place === 'admin'
                  ? headCells.concat([
                      {
                        id: 'employmentStatus',
                        label: '応募分類',
                        align: 'center',
                      },
                      {
                        id: 'acceptMailMagazine',
                        label: 'メルマガ購読',
                        align: 'center',
                      },
                      {
                        id: 'requestForRegistration',
                        label: 'スカウト登録代行',
                        align: 'center',
                      },
                    ])
                  : headCells.concat([
                      {
                        id: 'note',
                        label: 'メモ',
                        align: 'center',
                      },
                    ])
              }
            />
            <TableBody>
              {applicants.map((applicant) => (
                <TableRow key={applicant.applicantId} hover>
                  <TableCell>{formatJstTime(applicant.createdAt)}</TableCell>
                  <TableCell align='center'>
                    <MuiLink
                      component={Link}
                      to={
                        place === 'admin'
                          ? AdminRoutes.applicant(
                              applicant.clientId,
                              applicant.applicantId
                            )
                          : ClientRoutes.applicant(applicant.applicantId)
                      }
                    >
                      {applicant.applicantId}
                    </MuiLink>
                  </TableCell>
                  <TableCell>{applicant.formName}</TableCell>
                  <TableCell>{`${applicant.lastName} ${applicant.firstName}`}</TableCell>
                  <TableCell>
                    <MuiLink href={`mailto:${applicant.mailAddress}`}>
                      {applicant.mailAddress}
                    </MuiLink>
                  </TableCell>
                  {place !== 'admin' && (
                    <TableCell>
                      <Box
                        sx={{
                          fontSize: '13px',
                          whiteSpace: 'pre-wrap',
                          maxWidth: '200px',
                        }}
                      >
                        {getMemo(applicant.note)}
                      </Box>
                    </TableCell>
                  )}
                  {place === 'admin' && (
                    <TableCell sx={{ textAlign: 'center' }}>
                      {applicant.employmentStatus}
                    </TableCell>
                  )}
                  {place === 'admin' && (
                    <TableCell sx={{ textAlign: 'center' }}>
                      {applicant.acceptMailMagazine ? (
                        <CircleIcon fontSize='small' color='primary' />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  )}
                  {place === 'admin' && (
                    <TableCell sx={{ textAlign: 'center' }}>
                      {applicant.requestForRegistration ? (
                        <CircleIcon fontSize='small' color='primary' />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default TableApplicants
