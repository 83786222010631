import { Client } from '~/common/types/client'

class Routes {
  static home() {
    return `/`
  }

  static clients() {
    return `/clients`
  }

  static clientDetail(clientId: Client['clientId']) {
    return `/clients/${clientId}`
  }

  static clientEdit(clientId: Client['clientId']) {
    return `/clients/${clientId}/edit`
  }

  static applicants(clientId: Client['clientId']) {
    return `/clients/${clientId}/applicants`
  }

  static applicant(clientId: Client['clientId'], applicantId: string) {
    return `/clients/${clientId}/applicants/${applicantId}`
  }

  static reposts(clientId: Client['clientId']) {
    return `/clients/${clientId}/reposts`
  }

  static repost(clientId: Client['clientId'], repostId: string) {
    return `/clients/${clientId}/reposts/${repostId}`
  }
}

export default Routes
