import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { InputLabel, Button } from '@mui/material'
import { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { TOKEN_KEY } from '~/client/constants'

import { truncate } from '../libs/utils'
import { DataProps } from '../types/form'

type DownloadFileType = 'RESUME' | 'SKILL_SHEET' | 'PORTFOLIO'

type Props = {
  label: string
  value?: DataProps
  isAdmin?: boolean
  downloadFileType: DownloadFileType
}

const ConfirmFileField: FC<Props> = ({
  value,
  label,
  downloadFileType,
  isAdmin = true,
}) => {
  const { clientId, applicantId } = useParams()

  const handleDownloadAdmin = useCallback(() => {
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const file = `${process.env.API_URL}client/${clientId}/applicant/${applicantId}/download?type=${downloadFileType}`
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${localStorage.getItem(TOKEN_KEY)}`)

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobData) => {
        const objectUrl = window.URL.createObjectURL(blobData)
        anchor.href = objectUrl
        anchor.download = value?.name || ''
        anchor.click()
        window.URL.revokeObjectURL(objectUrl)
        anchor.remove()
      })
  }, [applicantId, clientId, downloadFileType, value?.name])

  const handleDownloadClient = useCallback(() => {
    const anchor = document.createElement('a')
    document.body.appendChild(anchor)
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${localStorage.getItem(TOKEN_KEY)}`)
    const file = `${process.env.API_URL}applicant/${applicantId}/download?type=${downloadFileType}`

    fetch(file, { headers })
      .then((response) => response.blob())
      .then((blobData) => {
        const objectUrl = window.URL.createObjectURL(blobData)
        anchor.href = objectUrl
        anchor.download = value?.name || ''
        anchor.click()
        window.URL.revokeObjectURL(objectUrl)
        anchor.remove()
      })
  }, [applicantId, downloadFileType, value?.name])

  if (!value) return null

  return (
    <div>
      <InputLabel sx={{ fontSize: '14px', mb: 0.5 }}>{label}</InputLabel>
      {isAdmin ? (
        <Button
          onClick={handleDownloadAdmin}
          variant='outlined'
          startIcon={<FileDownloadIcon />}
        >
          {truncate(value.name)}
        </Button>
      ) : (
        <Button
          onClick={handleDownloadClient}
          variant='outlined'
          startIcon={<FileDownloadIcon />}
        >
          {truncate(value.name)}
        </Button>
      )}
    </div>
  )
}

export default ConfirmFileField
