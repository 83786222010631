import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'

import Routes from '~/client/Routes'

const RequireAuthContainer = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn)
  const location = useLocation()

  if (!isLoggedIn) {
    return <Navigate to={Routes.login()} state={{ from: location }} replace />
  }

  return children
}

export default RequireAuthContainer
