/* eslint-disable @typescript-eslint/no-unused-vars */
import CircleIcon from '@mui/icons-material/Circle'
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Link as MuiLink,
} from '@mui/material'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import AdminRoutes from '~/admin/Routes'
import ClientRoutes from '~/client/Routes'
import TableHead, { HeadCell } from '~/common/components/TableHead'
import { formatJstTime } from '~/common/libs/timeUtils'
import { Repost } from '~/common/types/repost'

type Props = {
  reposts: Repost[]
  place?: 'admin' | 'client'
}

type HeadCellProps = Pick<
  Repost,
  | 'repostId'
  | 'requestJobCategory'
  | 'createdAt'
  | 'mailAddress'
  | 'acceptMailMagazine'
> & {
  name: string
}

const headCells: HeadCell<HeadCellProps>[] = [
  {
    id: 'repostId',
    label: 'ID',
    align: 'center',
  },
  {
    id: 'requestJobCategory',
    label: '希望職種',
    align: 'left',
  },
  {
    id: 'createdAt',
    label: '応募日',
    align: 'left',
  },
]

const TableReposts: FC<Props> = ({ reposts, place = 'admin' }) => {
  return (
    <>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead<HeadCellProps>
              headCells={
                place === 'admin'
                  ? headCells.concat([
                      {
                        id: 'acceptMailMagazine',
                        label: 'メルマガ購読',
                        align: 'center',
                      },
                    ])
                  : headCells
              }
            />
            <TableBody>
              {reposts.map((repost) => (
                <TableRow key={repost.repostId} hover>
                  <TableCell align='center'>
                    <MuiLink
                      component={Link}
                      to={
                        place === 'admin'
                          ? AdminRoutes.repost(repost.clientId, repost.repostId)
                          : ClientRoutes.repost(repost.repostId)
                      }
                    >
                      {repost.repostId}
                    </MuiLink>
                  </TableCell>
                  <TableCell>{repost.requestJobCategory}</TableCell>
                  <TableCell>{formatJstTime(repost.createdAt)}</TableCell>
                  {place === 'admin' && (
                    <TableCell sx={{ textAlign: 'center' }}>
                      {repost.acceptMailMagazine ? (
                        <CircleIcon fontSize='small' color='primary' />
                      ) : (
                        ''
                      )}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default TableReposts
