import { Avatar, Button, Stack, Typography } from '@mui/material'
import { useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useTicket } from '~/client/hooks/useTicket'
import { WorkerEntityType } from '~/client/types'

import { ScoutButton } from '../ScoutButton'

type Props = {
  worker: WorkerEntityType
}

const AvatarBlock = ({ worker }: Props) => {
  const navigate = useNavigate()

  const { execScout } = useTicket({
    callback: () => {
      navigate(`/scouts/${worker.workerId}`)
    },
  })

  const photoUrl = useMemo(() => {
    if (!worker.photoUrl) return undefined

    return `${process.env.API_URL.replace(/\/$/, '')}${worker.photoUrl}`
  }, [worker.photoUrl])

  return (
    <Stack spacing={1} alignItems='center'>
      <Avatar src={photoUrl} sx={{ width: 60, height: 60 }} />
      <Button
        variant='outlined'
        size='small'
        fullWidth
        component={Link}
        to={`/scouts/${worker.workerId}`}
        sx={{ background: '#fff' }}
      >
        詳細
      </Button>
      <ScoutButton
        worker={worker}
        execScout={() => execScout(worker.workerId)}
      />
      {worker.isScouted && (
        <Typography
          sx={{
            fontSize: '11px',
            color: 'red',
          }}
        >
          メッセージ送信済
        </Typography>
      )}
    </Stack>
  )
}

export default AvatarBlock
