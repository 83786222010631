/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Paper, Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  useGetMessageHistoryQuery,
  useGetWorkerPurchasedQuery,
} from '~/client/redux/apiSlice'

import MessageDialog from '../MessageDialog'
import { MessageHistoryDialog } from '../MessageHistoryDialog'
import WorkerInfoTable from '../WorkerCard/WorkerInfoTable'

import { DownloadButtons } from './DownloadButtons'

type Props = {
  workerId: string
  purchasedTicket: boolean
}

export const WorkerInfos = ({ purchasedTicket, workerId }: Props) => {
  const location = useLocation()

  const [isShowMessageDialog, setMessageDialog] = useState(!!location.state)

  const { data: worker, isLoading } = useGetWorkerPurchasedQuery(workerId, {
    skip: !purchasedTicket,
  })

  const [isOpenLastMessage, setIsOpenLastMessage] = useState(false)

  const { data: lastMessage } = useGetMessageHistoryQuery(workerId)

  if (isLoading || !worker) return null

  return (
    <>
      <Box mb={2}>
        <Box mb={2}>
          <Paper>
            <Box p={2}>
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 1 }}>
                  <Button
                    variant='contained'
                    onClick={() => setMessageDialog(true)}
                  >
                    スカウトメッセージを送る
                  </Button>
                  {!!lastMessage && (
                    <Button
                      sx={{ ml: 2 }}
                      onClick={() => setIsOpenLastMessage(true)}
                    >
                      前回送信したメッセージ
                    </Button>
                  )}
                </Box>
                <DownloadButtons worker={worker} />
              </Box>
              <Box mt={2}>
                <WorkerInfoTable worker={worker} isDetail isScouted />
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
      <MessageDialog
        open={isShowMessageDialog}
        onCancel={() => setMessageDialog(false)}
        workerId={worker.workerId}
      />
      <MessageHistoryDialog
        message={lastMessage}
        open={isOpenLastMessage}
        onClose={() => setIsOpenLastMessage(false)}
      />
    </>
  )
}
