/* eslint-disable @typescript-eslint/no-unused-vars */
import { Paper, Box, Button, TextField as MuiTextField } from '@mui/material'
import arrayMutators from 'final-form-arrays'
import { useSnackbar } from 'notistack'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import validator from 'validator'

import { useGetMeQuery, usePutMeMutation } from '~/client/redux/apiSlice'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import ErrorBanner from '~/common/components/ErrorBanner'
import PageTitle from '~/common/components/PageTitle'
import TextField from '~/common/components/TextField'

type FormValues = {
  subMailAddresses: string[]
}

const validate = (values: FormValues) => {
  const errors: { [key in keyof FormValues]?: string | string[] } = {}

  if (values.subMailAddresses) {
    const errorList: string[] = []
    values.subMailAddresses.forEach((sb, index) => {
      if (!sb) {
        errorList[index] = `メールアドレスを入力してください`
      }
      if (sb && !validator.isEmail(sb)) {
        errorList[index] = `メールアドレスの形式に誤りがあります`
      }
    })
    if (errorList.length) {
      errors.subMailAddresses = errorList
    }
  }

  return errors
}

const NotificationPage = () => {
  const { data: clientData, refetch } = useGetMeQuery()

  const { enqueueSnackbar } = useSnackbar()

  const [updateMe, { isLoading, error }] = usePutMeMutation()

  const onSubmit = async (values: FormValues) => {
    try {
      await updateMe(values)
      await refetch()
      enqueueSnackbar(`更新しました`)
    } catch (error) {
      console.log(error)
      enqueueSnackbar(`更新に失敗しました`)
    }
  }

  if (!clientData) return null

  const initialValues: FormValues = {
    subMailAddresses: clientData.subMailAddresses || [],
  }

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: '通知設定',
          },
        ]}
      />
      <PageTitle title={`通知設定`} />
      <ErrorBanner error={error} />
      <Paper sx={{ p: 5 }}>
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          mutators={{ ...arrayMutators }}
          validate={validate}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <MuiTextField
                  size='small'
                  label='通知メールアドレス1'
                  value={clientData.mailAddress}
                  disabled
                  helperText='このメールアドレスはシステムメールアドレスのため変更できません'
                />
              </Box>
              <FieldArray name='subMailAddresses'>
                {({ fields }) => {
                  return (
                    <>
                      {fields.map((name, index) => (
                        <Box
                          key={index}
                          width='50%'
                          mb={3}
                          sx={{ display: 'flex' }}
                        >
                          <TextField
                            size='small'
                            label={`通知メールアドレス${index + 2}`}
                            name={name}
                            fullWidth
                          />
                          <Button
                            color='error'
                            onClick={() => {
                              fields.remove(index)
                            }}
                          >
                            削除
                          </Button>
                        </Box>
                      ))}
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '45%',
                          marginTop: '-10px',
                        }}
                      >
                        <Button
                          variant='outlined'
                          size='small'
                          onClick={() => fields.push('')}
                        >
                          メールアドレスを追加
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          marginTop: '15px',
                          justifyContent: 'center',
                          width: '50%',
                        }}
                      >
                        <Button
                          type='submit'
                          variant='contained'
                          disabled={isLoading}
                          size='large'
                        >
                          設定を更新する
                        </Button>
                      </Box>
                    </>
                  )
                }}
              </FieldArray>
            </form>
          )}
        </Form>
      </Paper>
    </>
  )
}

export default NotificationPage
