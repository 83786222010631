/* eslint-disable @typescript-eslint/no-unused-vars */
import StarIcon from '@mui/icons-material/Star'
import { Box, Button } from '@mui/material'
import { useCallback } from 'react'

type Props = {
  onSubmit: () => void
  favorite: string
}

export const FavoriteButton = ({ onSubmit, favorite }: Props) => {
  return (
    <Box
      sx={{
        mt: 1,
        display: 'flex',
        gap: '10px',
        justifyContent: 'flex-end',
        color: '#333',
      }}
    >
      <Button
        onClick={() => onSubmit()}
        color='inherit'
        startIcon={
          <StarIcon
            sx={{
              color: favorite === '1' ? '#FBE21F' : '#666',
            }}
          />
        }
      >
        お気に入りのみ表示
      </Button>
    </Box>
  )
}
