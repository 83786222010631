/* eslint-disable @typescript-eslint/no-unused-vars */
export const parseStringToArray = (arg: string | null) => {
  const str = arg ?? ''

  return str
    .split(',')
    .filter((s) => !!s)
    .map((s) => {
      return s.replaceAll('"', '')
    })
}
