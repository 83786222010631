import { JobHistoryItem } from '../types'

/* eslint-disable @typescript-eslint/no-unused-vars */
export const parseCompanyList = (str: string | null) => {
  if (!str) return []

  try {
    const json = JSON.parse(str)

    return json
      .map((history: JobHistoryItem) => history.company)
      .filter((company: string) => !!company)
  } catch (error) {
    return []
  }
}
