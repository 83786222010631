/* eslint-disable @typescript-eslint/no-unused-vars */
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'

import { DRAWER_WIDTH } from '../constants'
import { setDrawer } from '../redux/appSlice'

const AppHeaderContainer = () => {
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn)

  const isOpenDrawer = useSelector((state) => state.app.isOpenDrawer)

  const dispatch = useDispatch()

  return (
    <AppBar
      color='secondary'
      sx={() =>
        isLoggedIn
          ? {
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              ml: `${DRAWER_WIDTH}px`,
            }
          : {}
      }
    >
      <Toolbar>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1, fontWeight: 'bold' }}
        >
          サクサク仕事探し 応募者管理画面
        </Typography>
      </Toolbar>
    </AppBar>
  )
}

export default AppHeaderContainer
