/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { WorkerEntityType } from '../types'

type Props = {
  execScout: () => void
  worker: WorkerEntityType
}

export const ScoutButton = ({ execScout, worker }: Props) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    if (!worker.purchasedTicket) {
      execScout()
    } else {
      navigate(`/scouts/${worker.workerId}`, {
        state: { message: true },
      })
    }
  }, [execScout, navigate, worker.purchasedTicket, worker.workerId])

  const buttonLabel = useMemo(() => {
    if (!worker.purchasedTicket) return `スカウトする`

    return `メッセージする`
  }, [worker.purchasedTicket])

  return (
    <Button
      variant='contained'
      size='small'
      onClick={handleClick}
      fullWidth
      color={worker.purchasedTicket ? 'secondary' : 'primary'}
    >
      {buttonLabel}
    </Button>
  )
}
