/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Stack } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { ScoutApplicationBanner } from '~/client/components/ScoutApplicationBanner'
import WorkerCard from '~/client/components/WorkerCard'
import WorkerSearch, { SearchProps } from '~/client/components/WorkerSearch'
import { useGetWorkersQuery } from '~/client/redux/apiSlice'
import PageTitle from '~/common/components/PageTitle'
import Pagination from '~/common/components/Pagination'
import usePageParams from '~/common/hooks/usePageParams'

type SearchPropsKeys = keyof SearchProps

const PageScouts = () => {
  const page = usePageParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const clientSearchProps: Partial<SearchProps> = useMemo(() => {
    const sort = searchParams.get('sort') || 'newer'
    const interest = searchParams.get('interest')
    const favorite = searchParams.get('favorite')

    const params: Partial<SearchProps> = { sort }

    const search = searchParams.get('search')

    if (search) {
      params.search = search
    }

    if (interest) {
      params.interest = interest
    }

    if (favorite) {
      params.favorite = favorite
    }

    return params
  }, [searchParams])

  const apiSearchProps = { ...clientSearchProps, page }

  const favoriteUpdatedAt = useSelector((state) => state.app.favoriteUpdatedAt)

  const { data, refetch } = useGetWorkersQuery(apiSearchProps)

  const enableScout = useSelector((state) => state.app.enableScout)

  const workers = data?.data || []

  const onSearch = useCallback(
    (searchProps: SearchProps) => {
      searchParams.set('page', '1')
      const keys = Object.keys(searchProps) as SearchPropsKeys[]
      keys.forEach((key) => {
        const value = searchProps[key]
        if (value) {
          searchParams.set(key, `${value}`)
        } else {
          searchParams.delete(key)
        }
      })
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  useEffect(() => {
    if (favoriteUpdatedAt) {
      refetch()
    }
  }, [favoriteUpdatedAt, refetch])

  return (
    <>
      <PageTitle title={`サクサクスカウト 登録者一覧`}></PageTitle>
      <WorkerSearch onSearch={onSearch} values={clientSearchProps} />
      <Pagination all={data?.all} />
      {data && data.all > 0 && (
        <Stack>
          {workers.map((worker, index) => (
            <WorkerCard key={worker.workerId} worker={worker} index={index} />
          ))}
        </Stack>
      )}
      {data && data.all < 1 && <Box>検索結果がありません</Box>}
      <Pagination all={data?.all} />
    </>
  )
}

export default PageScouts
