/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Badge,
  Paper,
  Button,
} from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DRAWER_WIDTH } from '../constants'
import { useGetTicketInfosQuery } from '../redux/apiSlice'
import { setEnableScout } from '../redux/appSlice'

const AppScoutHeaderContainer = () => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector((state) => state.app.isLoggedIn)

  const clientData = useSelector((state) => state.app.client)

  const enableScout = useSelector((state) => state.app.enableScout)

  const useTicketAt = useSelector((state) => state.app.useTicketAt)

  const clientId = clientData?.clientId

  const { data: ticketInfos, refetch } = useGetTicketInfosQuery(clientId, {
    skip: !clientId,
  })

  const usedTicket = ticketInfos?.usedTicket || 0
  const totalTicket = ticketInfos?.totalTicket || 0

  const remainTicketAmount = totalTicket - usedTicket

  useEffect(() => {
    if (useTicketAt) {
      refetch()
    }
  }, [useTicketAt])

  useEffect(() => {
    dispatch(setEnableScout(remainTicketAmount > 0))
  }, [remainTicketAmount])

  return (
    <AppBar
      color='secondary'
      sx={() =>
        isLoggedIn
          ? {
              width: `calc(100% - ${DRAWER_WIDTH}px)`,
              ml: `${DRAWER_WIDTH}px`,
            }
          : {}
      }
    >
      <Toolbar>
        <Typography
          variant='h6'
          component='div'
          sx={{ flexGrow: 1, fontWeight: 'bold' }}
        >
          サクサクスカウト
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 3,
          }}
        >
          {ticketInfos && (
            <>
              <Badge
                color='error'
                badgeContent={remainTicketAmount}
                showZero
                max={9999}
              >
                <Paper
                  variant='outlined'
                  sx={{
                    p: 1,
                    fontSize: '12px',
                    background: '#FFF',
                  }}
                >
                  保有チケット数
                </Paper>
              </Badge>
              <Badge
                color='error'
                badgeContent={usedTicket}
                showZero
                max={9999}
              >
                <Paper
                  variant='outlined'
                  sx={{
                    p: 1,
                    fontSize: '12px',
                    background: '#FFF',
                  }}
                >
                  利用チケット数累計
                </Paper>
              </Badge>
            </>
          )}
          <Button
            color='primary'
            variant='contained'
            size='small'
            href='https://okanechips.mei-kyu.com/tickets/'
            target='_blank'
          >
            スカウトチケットの追加
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default AppScoutHeaderContainer
