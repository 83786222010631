import { Paper, Box } from '@mui/material'
import { isUndefined } from 'lodash'
import { FC } from 'react'

import ConfirmField from '~/common/components/ConfirmField'
import { formatJstTime } from '~/common/libs/timeUtils'

import { Repost } from '../types/repost'

type Props = {
  repost?: Repost
}

const DetailRepost: FC<Props> = ({ repost }) => {
  if (isUndefined(repost)) return null

  return (
    <Paper sx={{ py: 2, px: 5 }}>
      <Box sx={{ display: 'grid', rowGap: '15px' }}>
        <ConfirmField label='ID' value={repost.repostId} />
        <ConfirmField label='応募日' value={formatJstTime(repost.createdAt)} />
        <ConfirmField
          label='再掲載希望職種'
          value={repost.requestJobCategory}
        />
        <ConfirmField label='再掲載理由' value={repost.reason} />
      </Box>
    </Paper>
  )
}

export default DetailRepost
