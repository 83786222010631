/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Paper, Box } from '@mui/material'
import { useCallback } from 'react'

import { TOKEN_KEY } from '~/client/constants'
import { WorkerEntityType } from '~/client/types'

type Props = {
  worker: WorkerEntityType
}

export const DownloadButtons = ({ worker }: Props) => {
  const resumeFileName = worker.resumeFile?.name
  const skillSheetFileName = worker.skillSheetFile?.name

  const handleDownload = useCallback(
    (type: string, fileName: string) => {
      const url = `${process.env.API_URL}client/worker/${worker.workerId}/${type}`
      const anchor = document.createElement('a')
      const headers = new Headers()
      headers.append(
        'Authorization',
        `Bearer ${localStorage.getItem(TOKEN_KEY)}`
      )

      document.body.appendChild(anchor)

      fetch(url, { headers })
        .then((response) => response.blob())
        .then((blobData) => {
          const objectUrl = window.URL.createObjectURL(blobData)
          anchor.href = objectUrl
          anchor.download = fileName
          anchor.click()
          window.URL.revokeObjectURL(objectUrl)
          anchor.remove()
        })
    },
    [worker.workerId]
  )

  return (
    <Box sx={{ display: 'flex', gap: 2 }}>
      {resumeFileName && (
        <Button
          variant='outlined'
          onClick={() => handleDownload('resume', resumeFileName)}
        >
          履歴書をダウンロード
        </Button>
      )}
      {skillSheetFileName && (
        <Button
          variant='outlined'
          onClick={() => handleDownload('skill_sheet', skillSheetFileName)}
        >
          職務経歴書・スキルシート(ポートフォリオ)をダウンロード
        </Button>
      )}
    </Box>
  )
}
