/* eslint-disable @typescript-eslint/no-unused-vars */
import StarIcon from '@mui/icons-material/Star'
import { IconButton } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
  useAddFavoriteMutation,
  useDeleteFavoriteMutation,
} from '~/client/redux/apiSlice'
import { setFavoriteUpdatedAt } from '~/client/redux/appSlice'
import { WorkerEntityType } from '~/client/types'

type Props = {
  worker: WorkerEntityType
}

export const Favorite = ({ worker }: Props) => {
  const dispatch = useDispatch()

  const [addMutation, addMutationState] = useAddFavoriteMutation()

  const [deleteMutation, deleteMutationState] = useDeleteFavoriteMutation()

  useEffect(() => {
    if (addMutationState.isSuccess || deleteMutationState.isSuccess) {
      dispatch(setFavoriteUpdatedAt(Date.now()))
    }
  }, [addMutationState.isSuccess, deleteMutationState.isSuccess, dispatch])

  const handleClick = useCallback(() => {
    if (worker.favorite) {
      deleteMutation(worker.workerId)

      return
    }
    addMutation(worker.workerId)
  }, [addMutation, deleteMutation, worker.favorite, worker.workerId])

  return (
    <>
      <IconButton onClick={handleClick}>
        <StarIcon sx={worker.favorite ? { color: '#FBE21F' } : {}} />
      </IconButton>
    </>
  )
}
