import { CircularProgress, Box } from '@mui/material'
import { FC } from 'react'

type Props = {
  isLoading: boolean
}

const ContentsLoading: FC<Props> = ({ isLoading }) => {
  if (!isLoading) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 300,
      }}
    >
      <CircularProgress />
    </Box>
  )
}

export default ContentsLoading
