import { format, subMinutes } from 'date-fns'
import { isUndefined } from 'lodash'

export const convertToJst = (utc?: string, template = 'yyyy-MM-dd HH:mm') => {
  if (isUndefined(utc)) return ''
  try {
    const utcDate = new Date(utc)
    // 9時間の差を引く
    const jstDate = subMinutes(utcDate, 60 * 9 * -1)

    return format(jstDate, template)
  } catch (error) {
    return ''
  }
}

export const formatJstTime = (jst?: string, template = 'yyyy-MM-dd HH:mm') => {
  if (isUndefined(jst)) return ''

  return format(new Date(jst), template)
}
