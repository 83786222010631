import { Button } from '@mui/material'
import { FC, useCallback } from 'react'

import downloadCsv from '~/client/libs/downloadCsv'
import { useGetRepostsQuery } from '~/client/redux/apiSlice'
import Breadcrumbs from '~/common/components/Breadcrumbs'
import ContentsLoading from '~/common/components/ContentsLoading'
import ErrorBanner from '~/common/components/ErrorBanner'
import PageTitle from '~/common/components/PageTitle'
import Pagination from '~/common/components/Pagination'
import TableReposts from '~/common/components/TableReposts'
import usePageParams from '~/common/hooks/usePageParams'

const PageReposts: FC = () => {
  const page = usePageParams()

  const { data, error, isSuccess, isLoading } = useGetRepostsQuery(page)

  const reposts = data?.data || []

  const handleDownloadCSV = useCallback(() => {
    downloadCsv(`${process.env.API_URL}repost/csv`, 'repost.csv')
  }, [])

  return (
    <>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: '再掲載リクエスト一覧',
          },
        ]}
      />
      <PageTitle title={`再掲載リクエスト一覧`}>
        <Button variant='contained' size='small' onClick={handleDownloadCSV}>
          CSVダウンロード
        </Button>
      </PageTitle>
      <ErrorBanner error={error} />
      <ContentsLoading isLoading={isLoading} />
      {isSuccess && (
        <>
          <Pagination all={data?.all} />
          <TableReposts reposts={reposts} place='client' />
          <Pagination all={data?.all} />
        </>
      )}
    </>
  )
}

export default PageReposts
