type NormalListProps = {
  list: string[]
}

export const NormalList = ({ list }: NormalListProps) => {
  return (
    <ul
      style={{
        padding: '0 0 0 2em',
        margin: '0',
        fontSize: '12px',
      }}
    >
      {list.map((li, index) => (
        <li key={index}>{li}</li>
      ))}
    </ul>
  )
}
