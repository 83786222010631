/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, SxProps } from '@mui/material'
import { FC } from 'react'

import { Client } from '~/common/types/client'

const labelStyle: SxProps = {
  fontSize: '10px',
  color: '#666',
}

const textStyle: SxProps = {
  fontSize: '13px',
  fontWeight: 'bold',
  marginBottom: 2,
}

const ClientData: FC<Client> = (props) => {
  return (
    <Box pt={2}>
      {/* <Box sx={labelStyle}>クライアントID</Box> */}
      {/* <Box sx={textStyle}>{props.clientId}</Box> */}
      {/* <Box sx={labelStyle}>クライアント名</Box> */}
      <Box sx={textStyle}>{props.companyName}</Box>
    </Box>
  )
}

export default ClientData
