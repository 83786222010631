export const TOKEN_KEY = `token_${process.env.APP_ENV}`

export const DRAWER_WIDTH = 250

export const INTEREST_OCCUPATION = [
  'インサイドセールス',
  '営業・フィールドセールス',
  'カスタマーサクセス',
  'エンジニア・プログラマー',
  'クリエイティブディレクター',
  'デザイナー',
  'UI/UXデザイナー',
  'WEBデザイナー',
  'WEBディレクター',
  'プランナー',
  'プロデューサー',
  'プロダクトマネージャー',
  'プロジェクトマネージャー',
  'プロダクションマネージャー',
  'マネージャー',
  '新規事業開発・Biz Dev',
  '商品企画・サービス企画',
  'マーケッター',
  'ストラテジスト',
  'コンサルタント',
  'リサーチャー・アナリスト',
  'キャピタリスト',
  '編集・ライター',
  '映像・動画ディレクター',
  '映像・動画エディター',
  '3DCGデザイナー',
  'カメラマン',
  'イラストレーター',
  'モーショングラファー',
  '広報・宣伝',
  'キュレーター',
  '法務・監査',
  '財務・会計・経理',
  '人事・採用・労務',
  'バックオフィス (総務・事務)',
  '一般職',
  '総合職',
  'その他',
]
