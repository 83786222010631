import { ConfirmProvider } from 'material-ui-confirm'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import reduxStore from './reduxStore'

if (process.env.APP_ENV === 'development') {
  const { worker } = require('~/common/mocks/browser')
  worker.start()
}

ReactDOM.render(
  <StrictMode>
    <Provider store={reduxStore}>
      <ConfirmProvider>
        <App />
      </ConfirmProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('app')
)
