/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Paper, Typography } from '@mui/material'
import { useCallback } from 'react'

import { useTicket } from '~/client/hooks/useTicket'
import { useGetWorkerQuery } from '~/client/redux/apiSlice'

import WorkerInfoTable from '../WorkerCard/WorkerInfoTable'

type Props = {
  workerId: string
  refetch: () => void
}

export const ExecScout = ({ workerId, refetch }: Props) => {
  const { execScout } = useTicket({
    callback: () => {
      refetch()
    },
  })

  const { data: worker, isLoading: isLoadingWorker } =
    useGetWorkerQuery(workerId)

  return (
    <Box mb={2}>
      <Paper>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Button
              variant='contained'
              size='large'
              onClick={() => {
                execScout(workerId)
              }}
            >
              スカウトする
            </Button>
            <Typography fontSize={12}>
              スカウトするとワーカーにスカウトメッセージが送れたり履歴書がダウンロードできたりします。
            </Typography>
          </Box>
          <Box mt={2}>{worker && <WorkerInfoTable worker={worker} />}</Box>
        </Box>
      </Paper>
    </Box>
  )
}
