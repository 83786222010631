import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

import { clientApi } from './redux/apiSlice'
import appSlice from './redux/appSlice'

const reduxStore = configureStore({
  reducer: {
    app: appSlice.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(clientApi.middleware),
})

export default reduxStore

export type ReduxStore = typeof reduxStore

export type ClientAppState = ReturnType<typeof reduxStore.getState>

// Promiseを返すdispatchを使う際にキャストする
// ex: const dispatch: AppDispatch = useDispatch()
export type AppDispatch = typeof reduxStore.dispatch

setupListeners(reduxStore.dispatch)
