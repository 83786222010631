/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  PayloadAction,
  createSlice,
  AnyAction,
  AsyncThunk,
} from '@reduxjs/toolkit'
import { get } from 'lodash'

import { TOKEN_KEY } from '~/client/constants'
import { Client } from '~/common/types/client'

import { clientApi } from './apiSlice'

export type ClientAppStoreState = {
  loading: boolean
  isLoggedIn: boolean
  isScout: boolean
  isOpenDrawer: boolean
  client?: Client
  enableScout: null | boolean
  isLoadingClientData: boolean
  useTicketAt?: number
  favoriteUpdatedAt?: number
}

const initialState: ClientAppStoreState = {
  loading: false,
  isLoggedIn: !!localStorage.getItem(TOKEN_KEY),
  isScout: false,
  isOpenDrawer: false,
  enableScout: null,
  isLoadingClientData: false,
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('/rejected')
}

const execLogout = (state: ClientAppStoreState) => {
  state.isLoggedIn = false
  try {
    localStorage.removeItem(TOKEN_KEY)
  } catch (error) {
    console.log(error)
  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    logout: execLogout,
    setIsScout: (state, action: PayloadAction<boolean>) => {
      state.isScout = action.payload
    },
    setDrawer: (state, action: PayloadAction<boolean>) => {
      state.isOpenDrawer = action.payload
    },
    setClient: (state, action: PayloadAction<Client | undefined>) => {
      state.client = action.payload
    },
    setLoadingClientData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingClientData = action.payload
    },
    setEnableScout: (state, action: PayloadAction<boolean>) => {
      state.enableScout = action.payload
    },
    setUseTicketAt: (state, action: PayloadAction<number>) => {
      state.useTicketAt = action.payload
    },
    setFavoriteUpdatedAt: (state, action: PayloadAction<number>) => {
      state.favoriteUpdatedAt = action.payload
    },
  },
  extraReducers: (builder) => {
    /** ログインした */
    builder.addMatcher(
      clientApi.endpoints.login.matchFulfilled,
      (state, action) => {
        try {
          localStorage.setItem(TOKEN_KEY, action.payload.token)
        } catch (error) {
          console.log(error)
        }
        state.isLoggedIn = true
      }
    )

    /** 認証エラーが発生 */
    builder.addMatcher(isRejectedAction, (state, action) => {
      if (get(action, 'payload.status') === 401) {
        execLogout(state)
      }
    })
  },
})

/** Action */
export const {
  setLoading,
  logout,
  setIsScout,
  setDrawer,
  setClient,
  setLoadingClientData,
  setEnableScout,
  setUseTicketAt,
  setFavoriteUpdatedAt,
} = appSlice.actions

/** Reducer */
export default appSlice
