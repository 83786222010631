import { InputLabel, Typography, Link } from '@mui/material'
import { isUndefined } from 'lodash'
import { FC } from 'react'

type Props = {
  value?: string
  label: string
  link?: string
  target?: string
}

const ConfirmField: FC<Props> = ({ value, label, link, target }) => {
  if (!value) return null

  return (
    <div>
      <InputLabel sx={{ fontSize: '14px', mb: 0.5 }}>{label}</InputLabel>
      <Typography sx={{ whiteSpace: 'pre-line' }}>
        {isUndefined(link) ? (
          <>{value}</>
        ) : (
          <Link href={link} target={target}>
            {value}
          </Link>
        )}
      </Typography>
    </div>
  )
}

export default ConfirmField
