/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Chip, Stack } from '@mui/material'
import { formatDistanceToNow } from 'date-fns'
import ja from 'date-fns/locale/ja'

import { WorkerEntityType } from '~/client/types'
import { formatJstTime } from '~/common/libs/timeUtils'

import { Favorite } from './Favorite'

type Props = {
  worker: WorkerEntityType
}

const Header = ({ worker }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Stack
        direction='row'
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        <Box sx={{ fontSize: '10px', wordBreak: 'break-all' }}>
          ID: {worker.workerId}
        </Box>
        <Box sx={{ fontSize: '10px' }}>{worker.age}歳</Box>
        <Box sx={{ fontSize: '10px' }}>{worker.prefecture}</Box>
        <Box sx={{ fontSize: '10px' }}>{worker.employmentStatus}</Box>
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Box>
          <Chip
            sx={{
              background: worker.scoutCount ? '#ff3d00' : '#ccc',
              color: '#fff',
            }}
            label={`スカウト数:${worker.scoutCount ?? 0}`}
          />
        </Box>
        <Box sx={{ fontSize: '10px' }}>
          登録日時: {formatJstTime(worker.createdAt)}
        </Box>
        <Box sx={{ fontSize: '10px' }}>
          最終ログイン:{` `}
          {formatDistanceToNow(new Date(worker.lastLoginAt), { locale: ja })}
        </Box>
        <Favorite worker={worker} />
      </Stack>
    </Box>
  )
}

export default Header
