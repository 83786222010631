/* eslint-disable @typescript-eslint/no-unused-vars */
import { Container, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'

import DrawerContainer from '~/client/containers/DrawerContainer'

import { DRAWER_WIDTH } from '../constants'

const PageWrapperContainer: FC = ({ children }) => {
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn)

  const isOpenDrawer = useSelector((state) => state.app.isOpenDrawer)

  const contentsOpen = isLoggedIn && isOpenDrawer

  return (
    <>
      <Box
        sx={{
          paddingTop: isLoggedIn ? 10 : 0,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          height: '100%',
          ml: `${DRAWER_WIDTH}px`,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Container maxWidth='xl' sx={{ flex: 1 }}>
            {children}
          </Container>
          <Box
            sx={{
              py: 2,
              px: 3,
              textAlign: 'right',
            }}
          >
            <Typography
              sx={{
                color: '#666',
                fontSize: '12px',
              }}
            >
              © TIME MACHINE Inc. All Rights Reserved
            </Typography>
          </Box>
        </Box>
      </Box>
      {isLoggedIn && <DrawerContainer />}
    </>
  )
}

export default PageWrapperContainer
