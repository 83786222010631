/* eslint-disable @typescript-eslint/no-unused-vars */
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {
  Divider,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Box,
  ListItemIcon,
  IconButton,
} from '@mui/material'
import { compact } from 'lodash'
import { useSnackbar } from 'notistack'
import { FC, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import { DRAWER_WIDTH } from '~/client/constants'
import { useGetMeQuery } from '~/client/redux/apiSlice'
import { logout, setDrawer, setClient } from '~/client/redux/appSlice'

import Routes from '../Routes'
import ClientData from '../components/ClientData'

const DrawerContainer: FC = () => {
  const isLoggedIn = useSelector((state) => state.app.isLoggedIn)

  const isOpenDrawer = useSelector((state) => state.app.isOpenDrawer)

  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const { data: clientData } = useGetMeQuery()

  const { pathname } = useLocation()

  const handleLogout = useCallback(() => {
    dispatch(logout())
    enqueueSnackbar('ログアウトしました')
    navigate('/')
  }, [dispatch, enqueueSnackbar, navigate])

  const isActive = useCallback(
    (path: string) => {
      const pathArray = compact(pathname.split('/'))
      if (pathArray.length === 0) return false

      return pathArray[0] === path.replace('/', '')
    },
    [pathname]
  )

  useEffect(() => {
    if (clientData) {
      dispatch(setClient(clientData))
    }
  }, [clientData, dispatch])

  if (!isLoggedIn) return null

  return (
    <>
      <MuiDrawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            // 常にOPENで
            width: DRAWER_WIDTH,
            boxSizing: 'border-box',
          },
        }}
        variant='permanent'
        anchor='left'
        open={isOpenDrawer}
      >
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            {/* <Toolbar>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  onClick={() => {
                    dispatch(setDrawer(false))
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
            </Toolbar> */}
            {/* <Divider /> */}
            <Box px={2}>{!!clientData && <ClientData {...clientData} />}</Box>
            <Divider />
            <List>
              <ListItem
                button
                component={Link}
                to={Routes.applicants()}
                selected={isActive(Routes.applicants())}
              >
                <ListItemText>採用応募者一覧</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to={Routes.reposts()}
                selected={isActive(Routes.reposts())}
              >
                <ListItemText>再掲載リクエスト</ListItemText>
              </ListItem>
              <ListItem
                button
                component={Link}
                to={Routes.scouts()}
                selected={isActive(Routes.scouts())}
              >
                <ListItemText>サクサクスカウト</ListItemText>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                button
                component={Link}
                to={Routes.notification()}
                selected={isActive(Routes.notification())}
              >
                <ListItemText>通知設定</ListItemText>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                button
                component='a'
                href={`${process.env.WP_URL}works/wp-admin`}
                target='_blank'
              >
                <ListItemText>募集掲載管理画面</ListItemText>{' '}
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
              </ListItem>
            </List>
            <List>
              <ListItem
                button
                component='a'
                href={`https://drive.google.com/drive/folders/18S7j2317-dctkiyzlX_qddKhIR4DmQBA?usp=sharing`}
                target='_blank'
              >
                <ListItemText>投稿・編集マニュアル</ListItemText>
                <ListItemIcon>
                  <PictureAsPdfIcon />
                </ListItemIcon>
              </ListItem>
            </List>
          </Box>
          <Box>
            <Divider />
            <List>
              <ListItem button onClick={handleLogout}>
                <ListItemText>ログアウト</ListItemText>
              </ListItem>
            </List>
          </Box>
        </Box>
      </MuiDrawer>
    </>
  )
}

export default DrawerContainer
