import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  Link as MuiLink,
} from '@mui/material'
import { isUndefined } from 'lodash'
import { FC, useMemo } from 'react'
import { Link } from 'react-router-dom'

type Breadcrumb = {
  path?: string
  label?: string
}

const Breadcrumbs: FC<{
  breadcrumbs?: Breadcrumb[]
}> = ({ breadcrumbs = [] }) => {
  const formattedBreadCrumbs = useMemo(() => {
    return [
      { path: breadcrumbs.length > 0 ? '/' : undefined, label: 'Home' },
      ...breadcrumbs,
    ]
  }, [breadcrumbs])

  return (
    <MuiBreadcrumbs sx={{ mt: 3 }}>
      {formattedBreadCrumbs.map((breadcrumb, index) =>
        isUndefined(breadcrumb.path) ? (
          <Typography key={index}>{breadcrumb?.label}</Typography>
        ) : (
          <MuiLink
            key={index}
            component={Link}
            underline='hover'
            color='inherit'
            to={breadcrumb.path}
          >
            {breadcrumb?.label}
          </MuiLink>
        )
      )}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
