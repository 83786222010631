import { jaJP } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'

const muiTheme = createTheme(
  {
    palette: {
      secondary: {
        main: '#FBE21F',
      },
    },
    /** コンポーネント設定 */
    components: {
      // グローバルスタイルの上書き
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
            background: '#F3F6F9',
          },
          '#app': {
            height: '100%',
          },
        },
      },
      MuiTextField: {
        // テキストフィールドのデフォルトスタイルの設定
        defaultProps: {
          InputLabelProps: {
            shrink: true,
          },
        },
      },
    },
  },
  jaJP
)

export default muiTheme
