/* eslint-disable @typescript-eslint/no-unused-vars */
import SearchIcon from '@mui/icons-material/Search'
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Stack,
} from '@mui/material'
import { useCallback, useState } from 'react'

import { INTEREST_OCCUPATION } from '~/client/constants'

import { FavoriteButton } from './FavoriteButton'

export type SearchProps = {
  search: string
  sort: string
  interest: string
  favorite: string
}

type Props = {
  onSearch: (searchProps: SearchProps) => void
  values: Partial<SearchProps>
}

const WorkerSearch = ({ onSearch, values }: Props) => {
  // 検索ワード
  const [search, setSearch] = useState(values.search || '')
  // ソート対象
  const [sort, setSort] = useState(values.sort || '')
  // 職種
  const [interest, setInterest] = useState(values.interest || '')
  // お気に入り
  const [favorite, setFavorite] = useState(values.favorite || '')

  const handleClear = useCallback(() => {
    setSearch('')
    setSort('')
    setInterest('')
    setFavorite('')
    onSearch({
      search: '',
      sort: '',
      interest: '',
      favorite: '',
    })
  }, [onSearch])

  const handleSubmit = useCallback(() => {
    onSearch({
      search,
      sort,
      interest,
      favorite,
    })
  }, [favorite, interest, onSearch, search, sort])

  const handleChangeFavorite = useCallback(() => {
    const newFavorite = favorite === '1' ? '' : '1'
    setFavorite(newFavorite)
    onSearch({
      search,
      sort,
      interest,
      favorite: newFavorite,
    })
  }, [favorite, interest, onSearch, search, sort])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              size='small'
              type='search'
              placeholder='Search...'
              sx={{ background: '#fff' }}
              value={search}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={3}>
            {/* 並び順 */}
            <FormControl fullWidth size='small'>
              <InputLabel>並び順</InputLabel>
              <Select
                disabled={false}
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value)
                }}
                label='並び順'
                sx={{ background: '#FFF' }}
              >
                <MenuItem value='newer'>登録日が新しい</MenuItem>
                <MenuItem value='older'>登録日が古い</MenuItem>
                <MenuItem value='login'>最終ログイン</MenuItem>
                <MenuItem value='scout'>スカウト数</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {/* 興味のある職種 */}
            <FormControl fullWidth size='small'>
              <InputLabel>興味のある職種</InputLabel>
              <Select
                value={interest}
                disabled={false}
                label='興味のある職種でフィルタ'
                sx={{ background: '#FFF' }}
                onChange={(e) => {
                  setInterest(e.target.value)
                }}
              >
                <MenuItem value=''>指定しない</MenuItem>
                {INTEREST_OCCUPATION.map((oc) => (
                  <MenuItem key={oc} value={oc}>
                    {oc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Stack direction='row' gap={1}>
              <Button
                variant='contained'
                fullWidth
                disabled={!interest && !sort && !search}
                type='submit'
              >
                検索する
              </Button>
              <Button
                variant='outlined'
                fullWidth
                type='button'
                onClick={() => handleClear()}
              >
                クリア
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <FavoriteButton
          favorite={favorite}
          onSubmit={() => {
            handleChangeFavorite()
          }}
        />
      </Box>
    </form>
  )
}

export default WorkerSearch
