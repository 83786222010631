import { Alert, AlertTitle } from '@mui/material'
import { isUndefined } from 'lodash'
import { FC } from 'react'

type Props = {
  error: string | undefined
}

const ErrorBanner: FC<Props> = ({ error }) => {
  if (isUndefined(error)) return null

  return (
    <Alert severity='error' sx={{ mb: 5 }}>
      <AlertTitle>Error</AlertTitle>
      <>{error}</>
    </Alert>
  )
}

export default ErrorBanner
