/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box } from '@mui/material'
import { isUndefined } from 'lodash'

import { WorkerEntityType } from '~/client/types'

import AvatarBlock from './AvatarBlock'
import Header from './Header'
import WorkerInfoTable from './WorkerInfoTable'

type Props = {
  worker: WorkerEntityType
  index?: number
}

const WorkerCard = ({ worker, index }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        background: !isUndefined(index) && index % 2 ? `#fffde7` : `#fff`,
        p: 1,
        borderBottom: '1px solid rgb(238,238,238)',
      }}
    >
      <Box sx={{ textAlign: 'center', width: 120 }}>
        <AvatarBlock worker={worker} />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Header worker={worker} />
        <WorkerInfoTable worker={worker} isScouted={worker.purchasedTicket} />
      </Box>
    </Box>
  )
}

export default WorkerCard
