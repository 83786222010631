import { Pagination as MuiPagination, Box } from '@mui/material'
import { isUndefined } from 'lodash'
import { FC, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMount } from 'react-use'

type Props = {
  /** 総記事数 */
  all?: number
  perPage?: number
}

const Pagination: FC<Props> = ({ all, perPage = 10 }) => {
  const [searchParams, setSearchParams] = useSearchParams('page')

  const [defaultPage, setDefaultPage] = useState<number | undefined>()

  const handleChange = useCallback(
    (page: number) => {
      searchParams.set('page', `${page}`)
      setSearchParams(searchParams)
      window.scrollTo(0, 0)
    },
    [searchParams, setSearchParams]
  )

  useMount(() => {
    const page = searchParams.get('page')
    if (!page) {
      setDefaultPage(1)
    } else if (isNaN(Number(page))) {
      return
    } else {
      setDefaultPage(Number(page))
    }
  })

  if (isUndefined(all) || isUndefined(defaultPage)) return null

  return (
    <Box display='flex' justifyContent='center' my={1}>
      <MuiPagination
        count={Math.ceil(all / perPage)}
        defaultPage={defaultPage}
        onChange={(event, page) => {
          handleChange(page)
        }}
      />
    </Box>
  )
}

export default Pagination
