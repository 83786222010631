import { Close as CloseIcon } from '@mui/icons-material'
import { CssBaseline, IconButton } from '@mui/material'
import { ThemeProvider } from '@mui/system'
import { SnackbarProvider } from 'notistack'
import { createRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Routes as BrowserRoutes, Route } from 'react-router-dom'
import { useLocation } from 'react-use'

import AppHeaderContainer from '~/client/containers/AppHeaderContainer'
import AppScoutHeaderContainer from '~/client/containers/AppScoutHeaderContainer'
import PageWrapperContainer from '~/client/containers/PageWrapperContainer'

import Routes from './Routes'
import RequireAuthContainer from './containers/RequireAuthContainer'
import MUI_THEME from './muiTheme'
import PageHome from './pages'
import Page404 from './pages/404'
import PageApplicants from './pages/applicants'
import PageApplicantDetail from './pages/applicants/detail'
import PageLogin from './pages/login'
import PageNotification from './pages/notification'
import PageReposts from './pages/reposts'
import PageRepostDetail from './pages/reposts/detail'
import PageScouts from './pages/scouts'
import PageScoutDetail from './pages/scouts/detail'
import { setIsScout } from './redux/appSlice'

const App = () => {
  const notistackRef = createRef() as any

  const location = useLocation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (!!location.pathname && location.pathname.includes('scout')) {
      dispatch(setIsScout(true))
    } else {
      dispatch(setIsScout(false))
    }
  }, [dispatch, location.pathname])

  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      dense
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      action={(key) => (
        <IconButton
          size='small'
          onClick={onClickDismiss(key)}
          sx={{ color: '#FFF' }}
        >
          <CloseIcon />
        </IconButton>
      )}
    >
      <ThemeProvider theme={MUI_THEME}>
        <CssBaseline />
        <BrowserRouter>
          <BrowserRoutes>
            <Route
              path='/'
              element={
                <RequireAuthContainer>
                  <PageHome />
                </RequireAuthContainer>
              }
            />
            <Route
              path='/applicants'
              element={
                <>
                  <AppHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageApplicants />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/applicants/:applicantId'
              element={
                <>
                  <AppHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageApplicantDetail />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/reposts'
              element={
                <>
                  <AppHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageReposts />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/reposts/:repostId'
              element={
                <>
                  <AppHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageRepostDetail />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/notification'
              element={
                <>
                  <AppHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageNotification />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/scouts'
              element={
                <>
                  <AppScoutHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageScouts />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route
              path='/scouts/:workerId'
              element={
                <>
                  <AppScoutHeaderContainer />
                  <PageWrapperContainer>
                    <RequireAuthContainer>
                      <PageScoutDetail />
                    </RequireAuthContainer>
                  </PageWrapperContainer>
                </>
              }
            />
            <Route path={Routes.login()} element={<PageLogin />} />
            <Route path='*' element={<Page404 />} />
          </BrowserRoutes>
        </BrowserRouter>
      </ThemeProvider>
    </SnackbarProvider>
  )
}

export default App
