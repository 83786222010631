/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Typography,
} from '@mui/material'
import { isNull } from 'lodash'
import { FC, useEffect, useMemo, useState } from 'react'

import { truncate } from '../libs/utils'

type Props = {
  onDownload: (formType: string) => void
  formNames: string[]
}

const ApplicantCsvDownloader: FC<Props> = ({ onDownload, formNames }) => {
  const [selected, setSelected] = useState<string>('')

  const cleanedFormNames = useMemo(() => {
    return formNames.filter((name) => !isNull(name))
  }, [formNames])

  useEffect(() => {
    if (!selected) return
    onDownload(selected)
  }, [onDownload, selected])

  return (
    <Box sx={{ width: 650, display: 'flex', alignItems: 'center' }}>
      <Typography>CSVダウンロード</Typography>
      <Box sx={{ flex: 1, paddingLeft: 2 }}>
        <FormControl size='small' fullWidth>
          <InputLabel>募集職種選択</InputLabel>
          <Select
            label='募集職種選択'
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value as string)
            }}
          >
            <MenuItem value='all'>すべて</MenuItem>
            {cleanedFormNames.map((name) => (
              <MenuItem key={name} value={name}>
                {truncate(name, 20)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default ApplicantCsvDownloader
