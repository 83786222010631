import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

/** APIは0スタートなので1を引く */
const usePageParams = () => {
  const [searchParams] = useSearchParams('page')

  return useMemo(() => {
    const page = searchParams.get('page')
    if (!page) {
      return 0
    }

    if (isNaN(Number(page))) {
      return undefined
    }

    return Number(page) - 1
  }, [searchParams])
}

export default usePageParams
